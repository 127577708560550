<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="uid" label="用户编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_nickname" label="用户昵称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="u_phone" label="用户手机" show-overflow-tooltip></el-table-column>
      <el-table-column prop="created_at" label="加入时间" show-overflow-tooltip>
        <template v-slot="s">{{s.row.created_at | date}}</template>
      </el-table-column>
      <el-table-column prop="join_type" label="加入方式" :formatter="joinTypeFormat" show-overflow-tooltip></el-table-column>
      <el-table-column prop="up_target_num" label="加入时直推达标人数" show-overflow-tooltip>
        <template v-slot="s"> <span v-if="s.row.join_type === 2">{{ s.row.up_target_num }}</span> </template>
      </el-table-column>
      <el-table-column prop="target_standard" label="加入时达标人数标准" show-overflow-tooltip>
        <template v-slot="s"> <span v-if="s.row.join_type === 2">直推{{ s.row.target_standard }}人即为达标用户</span> </template>
      </el-table-column>
      <el-table-column label="操作" width="300" show-overflow-tooltip>
        <template #header>
          <div class="flex-def">
            <y_choose_member @change="chooseNew">
              <el-button >添加用户</el-button>
            </y_choose_member>
            <el-button style="margin-left: 1rem" @click="allCheck">全员等级检测</el-button>
          </div>
        </template>
        <template v-slot="s">
          <el-button @click="remove(s.row)" type="danger">移出</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>

import y_choose_member from "@/components/shop/y_choose_member";
export default {
  components:{y_choose_member},
  name: "manage",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{

      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    allCheck(){
      this.$u.api.shop.plugin.capital.userAllCheck().then(res=>{
        this.$message.success(res);
        this.load();
      })
    },
    joinTypeFormat(e){
      let str = "";
      switch (parseInt(e.join_type)){
        case 1:
          str = "后台加入";
          break;
        case 2:
          str = "自动升级";
          break;
      }
      return str;
    },
    remove(e){
      this.$u.api.shop.plugin.capital.userDel({id:e.uid}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    chooseNew(e){
      this.$u.api.shop.plugin.capital.userAdd({id:e.id}).then(()=>{
        this.$message.success("操作成功")
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.capital.userSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>